<template>
    <div :class="['login-wrapper', ['', 'login-form-right', 'login-form-left'][direction]]">

        <div class="title-div flex ">
            <img class="title-img" src="../../assets/images/logo.png" alt="">
            <p class="title-p">洛阳市机动车驾驶人培训公众服务平台</p>
        </div>



        <el-form ref="loginForm" :model="form" :rules="rules" class="login-form ele-bg-white" size="large"
            @keyup.enter.native="doSubmit">
            <p class="login_title">登录</p>
            <el-form-item prop="username">
                <el-input placeholder="请输入证件号码" v-model="form.username" clearable />
            </el-form-item>
            <el-form-item prop="password">
                <el-input placeholder="请输入密码" v-model="form.password" show-password />
            </el-form-item>

            <el-form-item prop="code">
                <el-input v-model="form.code"  placeholder="验证码"  style="width: 63%"
                    @keyup.enter.native="handleLogin">
                </el-input>
                <div class="login-code" style="width: 37%">
                    <img   src="../../assets/images/logo.png" @click="getCode" class="login-code-img" />
                </div>
            </el-form-item>





            <div class="el-form-item">
                <el-checkbox v-model="form.remember" class="login-remember">记住密码
                </el-checkbox>
                <!-- <el-link
          @click="$router.push('/forget')"
          type="primary"
          class="ele-pull-right"
          :underline="false">忘记密码
        </el-link> -->
            </div>
            <div class="el-form-item">
                <el-button @click="doSubmit" :loading="loading" type="primary" class="login-btn" size="large">
                    {{ loading ? '登录中' : '登录' }}
                </el-button>
            </div>
            <div class="ele-text-center" style="margin-bottom: 10px;">
                <!--  <i
          class="login-oauth-icon el-icon-_qq"
          style="background-color:#3492ed;">
        </i>
        <i
          class="login-oauth-icon el-icon-_wechat"
          style="background-color:#4daf29;">
        </i>
        <i
          class="login-oauth-icon el-icon-_weibo"
          style="background-color: #CF1900;">
        </i> -->
            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            // 登录框方向, 0居中, 1居右, 2居左11
            direction: 1,
            // 加载状态
            loading: false,
            // 表单数据
            form: {
                username: '',
                password: '',
                captcha: '',
                remember: true,
                key: ''
            },
            // 表单验证规则
            rules: { // 表单验证规则
                username: [{
                    required: true,
                    message: '请输入账号',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }],
                captcha: [{
                    required: true,
                    message: '请按住滑块，拖动到最右边',
                    trigger: 'blur'
                }]
            },
            captcha: '',
        }
    },
    mounted() {
        this.$message(this.$store.state.user.token)
        //if (this.$store.state.user.token) this.$router.push(this.$route.query.from || '/');
        // this.changeCaptcha();
        // this.aliyunCaptcha();

    },
    methods: {
        getCode() {

        },
        /* 提交 */
        doSubmit() {
            this.$refs['loginForm'].validate((valid) => {
                if (!valid) {
                    return false;
                }
                // if (this.form.captcha.toLowerCase() !== this.text) {
                //   this.$message.error('验证码错误');
                //   return;
                // }
                this.loading = true;
                // let formData = new FormData();
                // for (let key in this.form) {
                //   formData.append(key, this.form[key]);
                // }
                let formData = new FormData();
                for (let key in this.form) formData.append(key, this.form[key]);
                this.$http.post('/login/login', this.form).then((res) => {
                    this.loading = false;
                    if (res.data.code === 0) {
                        this.$message({
                            type: 'success',
                            message: '登录成功'
                        });
                        this.$store.dispatch('user/setToken', res.data.data.access_token).then(
                            () => {
                                this.$router.push(this.$route.query.from || '/').catch(
                                    () => { });
                            });
                        if (this.$setting.parseUser) {
                            res.data.data.userInfo = this.$setting.parseUser(JSON.parse(res.data
                                .data.userInfo));
                        }
                        let user = res.data.data.userInfo;
                        this.$store.dispatch('user/setUser', user);
                        this.$store.dispatch('user/setRoles', user ? user.roles : null);
                        this.$store.dispatch('user/setAuthorities', user ? user.authorities : null);
                        // 设置节点权限
                        this.$store.dispatch('user/setPermission', user ? user.permissionList :
                            null);
                    } else {
                        //this.changeCaptcha();
                        // this.aliyunCaptcha();
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    //this.aliyunCaptcha();
                    this.loading = false;
                    this.$message.error("用户名或密码不正确");
                });
            });
        },
        /* 更换图形验证码 */
        changeCaptcha() {
            this.$http.get('/login/captcha').then(res => {
                if (res.data.code === 0) {
                    this.captcha = res.data.data.captcha;
                    this.form.key = res.data.data.key;
                    // this.form.captcha = this.text;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        aliyunCaptcha() {
            var that = this;
            var nc_token = ["FFFF0N0000000000A3E1", (new Date()).getTime(), Math.random()].join(':');
            var NC_Opt =
            {
                renderTo: "#captcha",
                appkey: "FFFF0N0000000000A3E1",
                scene: "nc_login",
                token: nc_token,
                customWidth: 300,
                trans: { "key1": "code0" },
                elementID: ["usernameID"],
                is_Opt: 0,
                language: "cn",
                isEnabled: true,
                timeout: 3000,
                times: 5,
                apimap: {
                    // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
                    // 'get_captcha': '//b.com/get_captcha/ver3',
                    // 'get_captcha': '//pin3.aliyun.com/get_captcha/ver3'
                    // 'get_img': '//c.com/get_img',
                    // 'checkcode': '//d.com/captcha/checkcode.jsonp',
                    // 'umid_Url': '//e.com/security/umscript/3.2.1/um.js',
                    // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
                    // 'umid_serUrl': 'https://g.com/service/um.json'
                },
                callback: function (data) {
                    that.form.captcha = data.csessionid + "," + data.sig + "," + nc_token + ",nc_login";
                }
            }
            var nc = new noCaptcha(NC_Opt)
            nc.upLang('cn', {
                _startTEXT: "请按住滑块，拖动到最右边",
                _yesTEXT: "验证通过",
                _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
                _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>",
            })
        }
    }
}
</script>
<style scoped>
  .login-code {
    width: 33%;
    height: 40px;
    float: right;


  }
.login-code-img{
  cursor: pointer;
      vertical-align: middle;
          height: 40px;

}

.login-wrapper {
    padding: 50px 20px;
    position: relative;
    box-sizing: border-box;
    background-image: url("~@/assets/images/bg-login.png");
    background-repeat: no-repeat;
    background-size: contain;

    min-height: calc(100vh - 196px);
    background-size: 100% 100%;
}

.login-wrapper:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.login-form {
    margin: 0 auto;
    width: 360px;
    max-width: 100%;
    padding: 25px 30px;
    position: relative;
    box-shadow: 0px 0px 30px 0px rgba(68, 151, 248, 0.3600);
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 2;
}

.title-div {
    margin: 0 auto;
    padding: 25px 30px;
    position: relative;
    border-radius: 4px;
    z-index: 2;

}

.title-p {
    margin-left: 12px;
    font-size: 30px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
}

.title-img {
    width: 45px;
    height: 45px;
    border-radius: 8px;
}

.login-form-right .login-form {
    margin: 0 0% 0 auto;
}

.login-form-left .login-form {
    margin: 0 auto 0 0%;
}

.login-form p {
    text-align: center;
    margin: 0 0 25px 0;


}

.login-form>.el-form-item {
    margin-bottom: 25px;
}

.login-input-group {
    display: flex;
    align-items: center;
}

.login-input-group ::v-deep .el-input {
    flex: 1;
}

.login-captcha {
    height: 38px;
    width: 102px;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    text-align: center;
    cursor: pointer;
}

.login-captcha:hover {
    opacity: .75;
}

.login-remember {
    color: #1989FA;
}

.login-btn {
    display: block;
    width: 100%;

    background: #1989FA;
    border-radius: 4px;
    border-color: #FFF;
}

.login-oauth-icon {
    color: #FFF;
    padding: 5px;
    margin: 0 10px;
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;
}

.login-copyright {
    color: #eee;
    padding-top: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
}

@media screen and (min-height: 550px) {
    .login-form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -220px;
    }

    .login-form-right .login-form,
    .login-form-left .login-form {
        left: auto;
        right: 0%;
        transform: translateX(0);
        margin: -220px 100px auto auto;
    }

    .login-form-left .login-form {
        right: auto;
        left: 0%;
    }

    .login-copyright {
        position: absolute;
        bottom: 20px;
        right: 0;
        left: 0;
    }
}

@media screen and (max-width: 768px) {

    .login-form-right .login-form,
    .login-form-left .login-form {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        margin-right: auto;
    }
}
</style>